import { computed, ref, watch } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import { isNil, omitBy } from 'lodash'

import { apiResellTicket } from '@/api'
import router from '@/router'
import store from '@/store'
import { env } from '@/libs/env'

import useToast from '@useToast'

export default function useResellTicketHandle() {
  const { toastSuccess, toastError } = useToast()

  function setExpiredDate() {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 1)
    return date.toISOString().split('T')[0]
  }
  const isMobileView = computed(() => {
    const breakPoint = store.getters['app/currentBreakPoint']
    return breakPoint === 'xs' || breakPoint === 'sm'
  })

  // Config
  const columns = [
    { key: 'stt', label: 'stt' },
    { key: 'type', label: 'type', class: 'text-nowrap' },
    { key: 'airlineCode', label: 'airlineCode' },
    { key: 'pnr', label: 'pnr' },
    { key: 'publishDate', label: 'publishDate' },
    { key: 'source', label: 'source' },
    { key: 'paxName', label: 'paxName' },
    { key: 'priceTicket', label: 'priceTicket' },
    { key: 'price', label: 'price' },
    { key: 'phone', label: 'phone' },
    { key: 'note', label: 'note' },
    { key: 'notePrivate', label: 'notePrivate' },
    { key: 'expiredDate', label: 'expiredDate' },
    {
      key: 'actions',
      label: 'actions',
      stickyColumn: !isMobileView.value,
      class: 'position-right-0',
    },
  ]
  const tableColumns = ref(columns)

  const resellTypes = ref([
    'IDENTITY',
    // 'AGENT'
  ])
  const paxTypes = ref([
    'ADULT',
    'CHILD',
    // 'INFANT'
  ])
  const resellTicketStatus = ref(['UNPUBLIC', 'PUBLIC', 'SOLD'])
  const airlineSupports = ref([
    {
      code: 'VJ',
      name: 'VietjetAir',
      logo_url:
        'https://airdata-cms.sgp1.cdn.digitaloceanspaces.com/airlines/VJ.png',
    },
  ])
  const mamaFullName = env.mamaFullName
  const sources = ref([mamaFullName])
  // Loading
  const loading = ref(true)
  const isManager = ref(router.currentRoute.name.includes('manager'))

  // Filter
  const filter = {
    size: 20,
    page: 1,
    sortBy: 'createdAt',
    sortDirection: 'DESC',
    isSortDirDesc: true,
    fromDate: undefined,
    toDate: undefined,
    type: undefined,
    airlineCode: undefined,
    reported: undefined,
    status: 'PUBLIC',
  }
  const filterResellTicket = ref(cloneDeep(filter))
  const filterSearchResellTicket = ref(cloneDeep(filter))

  // Request
  const resellTicket = {
    id: undefined,
    price: undefined,
    note: '',
    phone: undefined,
    refundTicketId: undefined,
    notePrivate: undefined,
    expiredDate: setExpiredDate(),
    paxType: undefined,
    paxName: undefined,
    type: 'IDENTITY',
    source: sources.value[0],
    priceTicket: undefined,
    pnr: undefined,
    isActive: true,
    status: 'PUBLIC',
    airlineCode: 'VJ',
    pnrs: [],
    pnrPaxs: [
      {
        pnr: null,
        paxList: [
          {
            paxType: 0,
            paxName: '',
            priceTicket: 0,
          },
        ],
      },
    ],
  }
  const createRTReq = ref(cloneDeep(resellTicket))
  const updateRTReq = ref(cloneDeep(resellTicket))

  // Const
  const resellTickets = ref([])
  const refResellTicket = ref(null)
  const searchResellTickets = ref([])
  const totalResellTicket = ref(0)
  const statistics = ref({})
  const reportDetails = ref([])

  // Function
  async function fetchResellTicket() {
    try {
      loading.value = true
      const params = omitBy(
        filterResellTicket.value,
        value => isNil(value) || value === '',
      )
      delete params.isSortDirDesc
      const { data } = isManager.value
        ? await apiResellTicket.findManagerAll(params)
        : await apiResellTicket.findAll(params)
      resellTickets.value = data.items
      totalResellTicket.value = data.count
      loading.value = false
      return data.items
    } catch (error) {
      toastError(error)
      return null
    }
  }
  async function searchResellTicket() {
    try {
      loading.value = true
      const params = omitBy(
        filterSearchResellTicket.value,
        value => isNil(value) || value === '',
      )
      delete params.isSortDirDesc
      const data = await apiResellTicket.search(params)
      searchResellTickets.value = data.items
      totalResellTicket.value = data.count
      loading.value = false
      return data.items
    } catch (error) {
      toastError(error)
      return null
    }
  }
  async function fetchClear() {
    filterResellTicket.value = cloneDeep(filter)
    fetchResellTicket()
  }
  async function createResellTicket() {
    try {
      if (createRTReq.value.isActive) {
        createRTReq.value.status = 'PUBLIC'
      } else {
        createRTReq.value.status = 'UNPUBLIC'
      }
      delete createRTReq.value.isActive
      delete createRTReq.value.pnrs
      const rs = await apiResellTicket.create(createRTReq.value)
      toastSuccess('resellTicket.createSuccess')
      createRTReq.value = cloneDeep(resellTicket)
      return rs
    } catch (error) {
      toastError(error)
      return undefined
    }
  }
  async function updateResellTicket(id) {
    try {
      if (
        Object.keys(updateRTReq.value).length > 0
        && updateRTReq.value.constructor === Object
      ) {
        await apiResellTicket.update(id, updateRTReq.value)
        toastSuccess('resellTicket.updateSuccess')
      }
      updateRTReq.value = cloneDeep(resellTicket)
    } catch (error) {
      toastError(error)
    }
    fetchResellTicket()
  }
  async function deleteResellTicket(id) {
    try {
      await apiResellTicket.delete(id)
      toastSuccess('resellTicket.deleteSuccess')
    } catch (error) {
      toastError(error)
    }
    fetchResellTicket()
  }
  async function deactiveResellTicket(id) {
    try {
      await apiResellTicket.updateStatus(id)
      toastSuccess('resellTicket.updateStatusSuccess')
    } catch (error) {
      toastError(error)
    }
    fetchResellTicket()
  }
  async function getStatistics() {
    try {
      const { data } = await apiResellTicket.statistics()
      statistics.value = data
    } catch (error) {
      toastError(error)
    }
  }
  async function createReport(id, req) {
    try {
      const { data } = await apiResellTicket.createReport(id, req)
      toastSuccess('resellTicket.createReportSuccess')
      statistics.value = data
    } catch (error) {
      toastError(error)
    }
  }
  function getReportsById(id) {
    return new Promise((resolve, reject) => {
      apiResellTicket
        .getReportsById(id)
        .then(res => {
          resolve(res.data)
        })
        .catch(error => {
          toastError(error)
          reject(error)
        })
    })
  }
  function getPaxInfoByPnr(pnr) {
    loading.value = true
    return new Promise(resolve => {
      apiResellTicket
        .getPaxInfoByPnr(pnr)
        .then(res => {
          const listPax = res.data.items.map(e => ({
            paxType: e.paxType,
            paxName: e.paxName,
            priceTicket: e.priceTicket,
          }))
          // createRTReq.value.paxList = listPax
          loading.value = false
          resolve(listPax)
        })
        .catch(error => {
          // createRTReq.value.paxList = [{}]
          toastError(error)
          loading.value = false
          resolve(null)
        })
    })
  }
  async function getSellById(id) {
    loading.value = true
    const { data } = await apiResellTicket.getSellById(id)
    loading.value = false
    return data
  }
  async function banAGByAGId(id, day) {
    loading.value = true
    const { data } = await apiResellTicket.banAGByAGId(id, day)
    loading.value = false
    return data
  }
  async function unbanAGByAGId(id) {
    loading.value = true
    const { data } = await apiResellTicket.unbanAGByAGId(id)
    loading.value = false
    return data
  }
  function sold(id) {
    loading.value = true
    return new Promise(resolve => {
      apiResellTicket
        .sold(id)
        .then(res => {
          loading.value = false
          toastSuccess()
          resolve(res)
        })
        .catch(error => {
          toastError(error)
          loading.value = false
          resolve(null)
        })
    })
  }
  function onMountedFunction() {
    if (router.currentRoute.name.includes('manager')) {
      tableColumns.value = columns
    } else {
      tableColumns.value = tableColumns.value.filter(
        column => ![
          'notePrivate',
          'expiredDate',
          'ticketNumber',
          'pnr',
        ].includes(column.key),
      )
    }
  }
  function resetForm() {
    createRTReq.value = cloneDeep(resellTicket)
  }

  //
  const dataMeta = computed(() => {
    const { page, size } = filterResellTicket.value

    const localItemsCount = refResellTicket.value
      ? refResellTicket.value?.localItems?.length
      : 0
    return {
      from: size * (page - 1) + (localItemsCount ? 1 : 0) || 0,
      to: size * (page - 1) + localItemsCount,
      of: totalResellTicket.value,
    }
  })
  watch(
    [filterResellTicket],
    val => {
      // change page size
      if (val[0].size !== filterResellTicket.value.size) {
        filterResellTicket.value.page = 1
      }
      filterResellTicket.value.sortDirection = filterResellTicket.value
        .isSortDirDesc
        ? 'DESC'
        : 'ASC'
      fetchResellTicket()
    },
    { deep: true },
  )
  watch(isManager, val => {
    if (val) {
      tableColumns.value = columns
    } else {
      tableColumns.value = tableColumns.value.filter(
        column => ![
          'notePrivate',
          'isActive',
          'expiredDate',
          'ticketNumber',
          'pnr',
        ].includes(column.key),
      )
    }
    fetchClear()
  })

  return {
    // Loading
    loading,
    isManager,
    refResellTicket,
    // Refs
    tableColumns,
    resellTypes,
    paxTypes,
    createRTReq,
    updateRTReq,
    resellTickets,
    searchResellTickets,
    totalResellTicket,
    statistics,
    reportDetails,
    airlineSupports,
    sources,
    resellTicketStatus,

    // Filters
    filterResellTicket,
    filterSearchResellTicket,
    dataMeta,

    // functions
    fetchClear,
    fetchResellTicket,
    createResellTicket,
    updateResellTicket,
    deleteResellTicket,
    deactiveResellTicket,
    createReport,
    getStatistics,
    getReportsById,
    getPaxInfoByPnr,
    getSellById,
    banAGByAGId,
    unbanAGByAGId,
    searchResellTicket,
    onMountedFunction,
    sold,
    resetForm,
  }
}
